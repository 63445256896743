<template>
  <div>
    <div class="row justify-content-md-center" v-cloak v-if="!state.loaded">
      <div class="col col-lg-2"></div>
      <div class="col-md-auto mt-20">
        <rotate-square5></rotate-square5>
      </div>
      <div class="col col-lg-2"></div>
    </div>
    <div class="card card-body printableArea" v-else>
      <h3>
        <b>Detail</b>
        <span class="pull-right">
          <button type="button" @click="goBack()" class="btn btn-inverse" title="Kembali">Kembali</button>
        </span>
      </h3>
      <hr>

      <div class="row">
        <div class="col-md-12 justify-content-md-center">

          <gtTabs>

            <gtTab title="Detail FaQ">
              <div class="col-md-6 pull-left">
                <small class="text-muted">Kategori</small>
                <h6>{{ state.detail.kategori === "" ? '-' : state.detail.kategori }}</h6>
                <small class="text-muxed">Keterangan</small>
                <h6>{{ state.detail.keterangan === "" ? '-' : state.detail.keterangan }}</h6>
                <small class="text-muxed">Tanggal Diterima</small>
                <h6>{{ tanggalditerima === "" ? '-' : tanggalditerima }}</h6>
              </div>

            </gtTab>

          </gtTabs>

        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { RotateSquare5 } from "vue-loading-spinner";
import ImageItem from "@/components/img/imgItem";
import { gtTab, gtTabs } from "@/components/gtTabs";
export default {
  components: {
    RotateSquare5,
    ImageItem,
    gtTab,
    gtTabs
  },

   data() {
    return {
      headers: {
        
        kategori: {
          label: "Nama File",
          sortable: false
        },
        keterangan: {
          label: "Nama Pengunggah",
          sortable: false
        },
        updated_at: {
          label: "Tanggal Upload",
          sortable: false
        }

      }
    };
  },

  computed: {

    base() {
      return this.baseUrl;
    },
    state() {
      return this.$store.state.faQ;
    },
    tanggalditerima() {
      return this.formatDate(this.state.detail.updated_at);
    },
    tanggaldokumen() {
      return this.formatDate(this.state.detail.tanggal_dokumen);
    },
    submitted() {
      return this.formatDate(this.state.detail.created_at);
    },
    modified() {
      return this.formatDate(this.state.detail.updated_at);
    }
  },
  mounted() {
    this.detailLoad();
  },
  methods: {
    detailLoad() {
      const state = {
        loaded: false
      };
      this.$store.commit("faQ/STATE", state);
      this.$store.dispatch("faQ/getFaQById", this.$route.params);
    },
    goBack() {
      this.$store.dispatch("faQ/onCancel");
    }
  }
};
</script>
